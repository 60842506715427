import { useCallback, useEffect, useRef, useState } from "react";
import { ButtonFrame, Card, InputField, PageBodyHeader } from "../../component"
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { checkStoreByStoreIdUserId, createProductOrder, fetchProductList } from "../../action/generalFunc";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IoChevronDown } from "react-icons/io5";

const StaffProductOrder = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<generalObj>({
        product: "",
        product_obj: null,
        current_quantity: ""
    });

    const [showList, setShowList] = useState<boolean>(false);
    const productRef = useRef<HTMLDivElement | null>(null);
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext()

    const account = useSelector((state:RootState) => state.account);

    const { id, store_id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        (async () => {
            setModalLoading(true);
            let result = await checkStoreByStoreIdUserId(account.token, store_id, id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store_data.name} - ${result.data.store_data.organization}`)
                setVendorName(`${result.data.vendor_data.username}`)
            }
            
            (async () => {
                let product_result = await fetchProductList(account.token, {
                    vendor:id,
                    store:store_id
                });
                if (product_result && product_result.data.product.length > 0) {
                    setProductList(product_result.data.product);
                }else {
                    setProductList([]);
                }
            })();
            setModalLoading(false);
        })();
    }, [account, id, store_id, setModalLoading]);

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const toggleShow = useCallback((val:boolean) => {
        setShowList(val);
    }, []);

    const productSearch = async (val:string) => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        updateFormData("product", val);
        
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || item.description.toLowerCase().includes(val.toLowerCase()) || item.size.toLowerCase().includes(val.toLowerCase()) || (item.unit && item.unit.toLowerCase().includes(val.toLowerCase()))) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
    }

    const selectProduct = (item:generalObj) => {
        setFormData({...formData, product_obj: item, product:""});
    }

    const updateToObj = () => {
        updateFormData("product", "");
        updateFormData('product_obj', null);
        toggleShow(false);
    }

    const addProduct = async () => {
        if (!account) return;
        if (formData.current_quantity === "") {
            toast("Quanity is required");
            return;
        }
        
        if (parseInt(formData.current_quantity) < 0) {
            toast("Quanity must be a number");
            return;
        }
        setModalLoading(true);
        let api_data:generalObj = {
            product_id: formData.product_obj.id,
            vendor: id,
            store: store_id,
            current_quantity: formData.current_quantity
        };

        let result = await createProductOrder(account.token, api_data);
        if (result) {
            toast(result.message);
            navigate(`${UrlTypes.STAFF_PRODUCT_LIST}${id}/${store_id}/`);
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader 
                title="Product List" 
                desc="Create Product list item" 
            />
            <h3 style={{marginBottom:"18px", textAlign:"center"}}>{`Create Product List for ${vendorName.toLocaleUpperCase()} in ${storeName} store`}</h3>

            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div className="product_order__container" ref={productRef}>
                        <div className="product_order__item" id="step1">
                            <div className="searchdrop" style={{display:'flex', gap:"24px", alignItems:"center"}}>
                                {formData.product_obj ? (
                                    <div className="inputFieldMain" style={{width:"100%"}}>
                                        <div className="labelText">
                                            <label>Search Product *</label>
                                        </div>
                                        <ButtonFrame btn_text={`${formData.product_obj.name} - ${formData.product_obj.size}${formData.product_obj.unit}`} btn_style={{padding:"8px 24px", maxHeight:"40px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px", textAlign:"left", width:"100%"}} onPress={() => updateToObj()} />
                                    </div>
                                ): (
                                    <>
                                        <InputField label={"Search Product *"} inputProp={{value:formData.product, onFocus:() => toggleShow(true)}} onTextChange={(evt) => productSearch(evt)} containerStyle={{width:"100%"}} isIconRight={<IoChevronDown size={14} color={"#B2B5B2"} />}  />
                                        <div className={`inputDropDown ${showList ? "active" : ""}`} style={{top:"60px"}}>
                                            <ul>
                                                {productList.length > 0 && productList.map((item:generalObj, index:number) => (
                                                    (item.show || item.show === undefined) && (
                                                        <li key={index}>
                                                            <button onClick={() => selectProduct(item)}>{item.name} - {item.size}{item.unit}</button>
                                                        </li>
                                                    )
                                                ))}
                                                {productList.length === 0 && (
                                                    <li>
                                                        <button>Searching for Product...</button>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>

                            <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"44px"}}>
                                <InputField label={`Quantity in store`} inputProp={{value:formData.current_quantity, placeholder:"Enter Qty", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("current_quantity", evt)} containerStyle={{width:"60%"}} />
                            </div>

                            <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                                <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addProduct()} />
                                <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => navigate(`${UrlTypes.STAFF_STORE_MENU}${id}/${store_id}/`)} />
                            </div>
                        </div>
                    </div>
                </>
            </Card>
        </>
    )
}

export default StaffProductOrder;